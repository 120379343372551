import axios from 'axios';

const sprintf = require('sprintf-js').sprintf;

export default class ReportsClient {
  constructor(type) {
    this.type = type;
  }

  async create({pathParameters, data} = {}) {
    let url = `${process.env.VUE_APP_REPORTS_V1_BASE_URL}/entries/${this.type}/%(entryId)s/scheduled-reports`;
    return axios({
      url: `${sprintf(url, pathParameters)}`,
      method: 'post',
      data: data,
    });
  }

  async retrieve({pathParameters} = {}) {
    let url = `${process.env.VUE_APP_REPORTS_V1_BASE_URL}/entries/${this.type}/%(entryId)s/scheduled-reports/%(scheduledReportId)s`;
    return axios({
      url: `${sprintf(url, pathParameters)}`,
      method: 'get',
    });
  }

  async update({pathParameters, data} = {}) {
    let url = `${process.env.VUE_APP_REPORTS_V1_BASE_URL}/entries/${this.type}/%(entryId)s/scheduled-reports/%(scheduledReportId)s`;
    return axios({
      url: `${sprintf(url, pathParameters)}`,
      method: 'put',
      data: data
    });
  }

  async delete({pathParameters} = {}) {
    let url = `${process.env.VUE_APP_REPORTS_V1_BASE_URL}/entries/${this.type}/%(entryId)s/scheduled-reports/%(scheduledReportId)s`;
    return axios({
      url: `${sprintf(url, pathParameters)}`,
      method: 'delete',
    });
  }

  async all({pathParameters} = {}) {
    let url = `${process.env.VUE_APP_REPORTS_V1_BASE_URL}/entries/${this.type}/%(entryId)s/scheduled-reports/`;
    return axios({
      url: `${sprintf(url, pathParameters)}`,
      method: 'get',
    });
  }
}
